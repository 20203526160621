import {Observable} from 'rxjs';
import Api from '../../../../core/services/Api';
import {ExchangeRateResponse, ExchangeRateWidgetResponse} from '../model/exchangeRate.model';
import {EXCHANGE_RATE_PATH, EXCHANGE_RATE_WIDGET_PATH} from '../CONSTANTS';

export class ExchangeRateService {
  public getExchangeRate(): Observable<ExchangeRateWidgetResponse> {
    return Api.get<ExchangeRateWidgetResponse>(EXCHANGE_RATE_WIDGET_PATH);
  }

  public getTotalExchangeRate(): Observable<ExchangeRateResponse> {
    return Api.get<ExchangeRateResponse>(EXCHANGE_RATE_PATH, { page_size: 1000 });
  }
}
